class Buil{
	constructor(_obj,shader,type,_stage){

		let obj=_obj;

		let BuilObj3D=new THREE.Object3D();
		BuilObj3D.name="BuilObj3D";
		_stage.add(BuilObj3D);

		

		let meshs=analizeLoadFbx(_obj);

		let buil3Ds=[];

		for(var n=0;n<30;n++){
			var objsD=new THREE.Object3D();
			buil3Ds.push(objsD);
		}

		let isekis=new THREE.Object3D();

		_stage.add(isekis);

		let okujoColor=new THREE.Color(0xa8bdab);
		let madoColor=new THREE.Color(0xebe7df);
		madoColor=new THREE.Color(0xe3ded4);
		let builColor=new THREE.Color(0xf3f7f5);

		let madMaterial;

		let map1=new THREE.TextureLoader().load("img/buil_map1.png");
		let bamp1=new THREE.TextureLoader().load("img/buil_bump1.png");

		let map2=new THREE.TextureLoader().load("img/buil_map2.png");
		let bamp2=new THREE.TextureLoader().load("img/buil_bump2.png");

		let material1;//=new THREE.MeshPhongMaterial({map:map2,bumpMap:bamp2,bumpScale:10,name:"newBuil3",spacular:0xffffff,shiness:10});
		let material2;//=new THREE.MeshPhongMaterial({map:map2,bumpMap:bamp2,bumpScale:10,name:"newBuil3",spacular:0xffffff,shiness:10});

		let lightOffset=-0.0025;
		let hueOffset=-0;


		$.each(meshs.children,(id,el)=>{
			
			let materialName=el.material.name;

			el.material.metalness=0.5;
			el.material.shinness=1;


			if(materialName.match(/okujo/)){
				//console.log("materialName="+materialName)
				//el.material.color=okujoColor.offsetHSL(hueOffset,0,lightOffset);
				if(materialName.match(/bil3|bil6|bilB2|bilB8/)){
					//ヘリポート
					el.material.emissive=new THREE.Color(0xaaaa66);
					el.material.emissiveIntensity=0.25;
				}else if(materialName.match(/bilB3|bilB5/)){
					el.material.emissive=new THREE.Color(0x006699);
					el.material.emissiveIntensity=0.25;
				}else if(materialName.match(/bil4|bilB4/)){
					el.material.emissive=new THREE.Color(0xff0000);
					el.material.emissiveIntensity=-0.15;
				}else if(materialName.match(/bil7|bilB7/){
					//とんがりエンパイア
					el.material.emissive=new THREE.Color(0x6666aa);
					el.material.emissiveIntensity=-0.25;
					el.material.color=new THREE.Color(0.5,0.5,0.5);

				}else{
					el.material.emissive=new THREE.Color(0x339933);
					el.material.emissiveIntensity=0.25;
				}

				el.material.emissive.offsetHSL(0,0,-0.2);



				
			}else if(materialName.match(/mado/)){
				if(!madMaterial){
					madMaterial=el.material.clone();
					madMaterial.bumpMap=el.material.map;
					madMaterial.bumpIntensity=0.5;
					madMaterial.needsUpdate=true;
				}
				if(materialName.match(/bil4|bil6|bil13/)){
					el.material.color=new THREE.Color(0.6,0.7,0.75);
					el.material.color.offsetHSL(-0.45,-0.2,-0.2);
					el.material.emissive=new THREE.Color(0xffffff);
					el.material.emissiveIntensity=-0.05;

				}else if(materialName.match(/bil7|bil16|bil19|bilB7/){
					el.material.color=new THREE.Color(0.6,0.7,0.7).offsetHSL(-0.5,-0.1,-0.0);
					el.material.emissive=new THREE.Color(0xffcccccc);
					el.material.emissiveIntensity=-0.5;
					el.material.color=new THREE.Color(0.4,0.6,0.4).offsetHSL(0,-0.1,-0.05);
				}else if(materialName.match(/bil5/){
					el.material.color=new THREE.Color(0.7,0.7,0.9).offsetHSL(0,-0.1,-0.2);
					;
				
				}else if(materialName.match(/bilB8|bilB9/){
					el.material.color=new THREE.Color(0.8,0.8,0.9).offsetHSL(0.4,0,0);

				}else{
					//el.material.color=new THREE.Color(0xff0000)
				}

				if(el.material.name.match(/bil2/)){
					el.material.emissive=new THREE.Color(0xccccff);
					el.material.color=new THREE.Color(0.4,0.4,0.2).offsetHSL(0.2,-0.5,0.1);
					el.material.emissiveIntensity=0.3;
				}else if(el.material.name.match(/bilB7/)){
					el.material.color=new THREE.Color(0.45,0.6,0.35).offsetHSL(0,-0.1,0.2);

				}else if(el.material.name.match(/bil9/)){
					//el.material.color=new THREE.Color(0.7,0.7,0.7)
					el.material.emissive=new THREE.Color(0x6666cc);
					el.material.emissiveIntensity=-0.5;
					el.material.color=new THREE.Color(0.8,0.8,0.8).offsetHSL(0,0,-0.1);
				}else if(el.material.name.match(/bilB1/)){
					el.material.color=new THREE.Color(0.6,0.8,0.6).offsetHSL(0,0,0.1);
				}else if(el.material.name.match(/bilB2/)){
					el.material.color=new THREE.Color(0.4,0.5,0.4).offsetHSL(0,0,0.1);

					//el.material.color=new THREE.Color(0xff0000);
				}else if(el.material.name.match(/bil3|bilB6/)){
					el.material.emissive=new THREE.Color(0xffffff);
					el.material.emissiveIntensity=-0.35;

				}else if(el.material.name.match(/bil5/)){
					el.material.color=new THREE.Color(0.5,0.5,0.4).offsetHSL(0.2,-0.1,0);
					el.material.emissive=new THREE.Color(0xffffff);
					el.material.emissiveIntensity=0.05;

				}else if(el.material.name.match(/bil8|bil11/)){
					el.material.color=new THREE.Color(0.5,0.5,0.7).offsetHSL(0,0,0);
					el.material.emissive=new THREE.Color(0xffffff);
					el.material.emissiveIntensity=0.25;

				}else if(el.material.name.match(/bilB5/)){
					el.material.color=new THREE.Color(0.5,0.5,0.5).offsetHSL(0,0,0);
				}else if(el.material.name.match(/bil15/){
					el.material.emissive=new THREE.Color(0xffffff);
					el.material.emissiveIntensity=-0.5;
					el.material.color=new THREE.Color(0.5,0.5,0.4)

					//el.material.color=new THREE.Color(0xff0000);
				}else if(el.material.name.match(/bil14/){
					el.material.emissive=new THREE.Color(0xffffff);
					el.material.emissiveIntensity=-0.25;
					el.material.color=new THREE.Color(0.7,0.7,0.6)

					//el.material.color=new THREE.Color(0xff0000);
				}else if(el.material.name.match(/bil18/){
					el.material.emissive=new THREE.Color(0xffffff);
					el.material.emissiveIntensity=-0.25;
					el.material.color=new THREE.Color(0.5,0.5,0.4)

					//el.material.color=new THREE.Color(0xff0000);
				}else if(el.material.name.match(/bil17/){
					//el.material.emissive=new THREE.Color(0xffffff);
					//el.material.emissiveIntensity=-0.25;
					el.material.color=new THREE.Color(0.5,0.5,0.4)

					//el.material.color=new THREE.Color(0xff0000);
				}else if(el.material.name.match(/bil12/){
					el.material.emissive=new THREE.Color(0xffffff);
					el.material.emissiveIntensity=-0.35;
					//el.material.color=new THREE.Color(0xff0000); 

					//el.material.color=new THREE.Color(0.5,0.5,0.4)

					//el.material.color=new THREE.Color(0xff0000);
				}else{
					el.material.emissive=new THREE.Color(0xffffff);
					el.material.emissiveIntensity=-0.2;
					//el.material.color=new THREE.Color(0xff0000);
					

					//el.material.color.offsetHSL(0,0,-0.1);
				}

				if(el.material.name.match(/bilB10|bilB11|bilB1_/){

					el.material.color=new THREE.Color(0.8,0.8,0.8).offsetHSL(0,0,-0.1);
				}
				if(el.material.name.match(/bilB4/){
					el.material.color=new THREE.Color(0.8,0.9,0.9);
				}


				
				//el.material.color.offsetHSL(0,0,-0.05);
				//el.material.emissive.offsetHSL(0,0,-0);

				//el.material=madMaterial;
			}else if(materialName.match(/iseki/)){
				//el.material.color=new THREE.Color(0.5,0.55,0.6);
				el.material.color=new THREE.Color(0.5,0.5,0.5);
				//el.material.emissive=new THREE.Color(0x666666);

			}else{
				if(el.material.name.match(/bil3|bilB5|bil7|bil9/)){
					el.material.color=new THREE.Color(0xaabbaa).offsetHSL(0,0,-0.2);
				}
				el.material.color.offsetHSL(0,0,-0.1);

				if(el.material.name.match(/bil2|bilB1/)){
					el.material.color=new THREE.Color(1,1,1).offsetHSL(0,0,-0.4);

				}else if(el.material.name.match(/bilB2/)){
					el.material.color=new THREE.Color(0xaaaaaa).offsetHSL(0,0,-0.1);

				}else if(el.material.name.match(/bilB5/)){
					//el.material.color=new THREE.Color(0xff0000).offsetHSL(0,0,-0.1);

				}else if(el.material.name.match(/bil5/)){
					el.material.color=new THREE.Color(0x888888).offsetHSL(0,0,-0.1);

				}else if(el.material.name.match(/bil8/)){
					el.material.color=new THREE.Color(0x000099);
					el.material.emissive=new THREE.Color(0xffffff);
					el.material.emissiveIntensity=0.5;

				}else if(el.material.name.match(/bil11/)){
					//console.log("el11.material.name="+el.material.name)
					//el.material.color=new THREE.Color(0xff0000);
				}else if(el.material.name.match(/bil12/)){
					//console.log("el12.material.name="+el.material.name)
					//el.material.color=new THREE.Color(0xff0000);
				}else if(el.material.name.match(/bil13/)){
					//console.log("el13.material.name="+el.material.name)
					el.material.color=new THREE.Color(0x999999);
				}else if(el.material.name.match(/bil14/)){
					//console.log("el14.material.name="+el.material.name)
					el.material.color=new THREE.Color(0x556677); 
				}else if(el.material.name.match(/bil15/)){
					//console.log("el15.material.name="+el.material.name)
					//el.material.color=new THREE.Color(0xff0000); 
				}else if(el.material.name.match(/bil16/)){
					//console.log("el16.material.name="+el.material.name)
					//el.material.color=new THREE.Color(0xff0000); 
				}else if(el.material.name.match(/bil17/)){
					//console.log("el17.material.name="+el.material.name)
					//el.material.color=new THREE.Color(0xff0000); 
				}else if(el.material.name.match(/bil18/)){
					//console.log("el18.material.name="+el.material.name)
					//el.material.color=new THREE.Color(0xff0000); 
				}else if(el.material.name.match(/bil19/)){
					//console.log("el19.material.name="+el.material.name)
					//el.material.color=new THREE.Color(0xff0000); 
				}else{

				}

				

				

			}

			//el.material.emissiveIntensity=-0.25;

			let builNum=Number(materialName.match(/\d\d?/)[0]);
			let builKey=materialName.match(/bilB/);
			let isekiKey=materialName.match(/iseki/);

			if(isekiKey){
				//console.log(materialName);
				//el.material.color=new THREE.Color(0x8899aa);
				el.material.color=new THREE.Color(0x667799);

				isekis.add(el.clone());

			}else if(builKey){
				buil3Ds[builNum-1+19].add(el.clone());
				buil3Ds[builNum-1+19].name="builB"+builNum;
				//console.log("B:num="+(builNum-1+11)+":name="+buil3Ds[builNum-1+11].name);

			}else{
				buil3Ds[builNum-1].add(el.clone());
				buil3Ds[builNum-1].name="builA"+builNum;

				//console.log("A:num="+(builNum-1)+":name="+buil3Ds[builNum-1].name)
			}
			
		})

		//material1.map=map1;
		//material1.bumpMap=bamp1;
		//material2.map=map2;
		//material2.bumpMap=bamp2;

		//console.log("buil3Ds=");
		//console.log(buil3Ds)

		$.each(buil3Ds,(id,el)=>{
			let targetMeshes=el.children;

			//console.log("name="+el.name)

			let unionBox=new THREE.Box3();
			$.each(targetMeshes,(id0,el0)=>{
				
				el0.geometry.computeBoundingBox();
				unionBox.union(el0.geometry.boundingBox);
				
			})

			let center=unionBox.getCenter(new THREE.Vector3());
			//console.log(" center="+ center.x+","+ center.y+","+ center.z)
			let from=new THREE.Vector3(0,1,0);
			let to=center.clone().normalize();
			let q=new THREE.Quaternion().setFromUnitVectors(from,to);


			let revQ=q.clone().inverse();

			let bacePosition=new THREE.Vector3(0,EARTH.circleR/0.3,0).applyQuaternion(q);

			if(id==0){
				var testMesh=new THREE.Mesh(new THREE.SphereBufferGeometry(10,12,6),new THREE.MeshBasicMaterial({color:0xff0000}));
				testMesh.position.copy(bacePosition);
				testMesh.applyQuaternion(revQ);
			}

			//let bacePosition=new THREE.Vector3(0,EARTH.circleR/0.3,0).applyQuaternion(q);
			let boxSize=unionBox.getSize(new THREE.Vector3()).applyQuaternion(revQ)
			let harfHeight=boxSize.y/2;
			//console.log("harfHeight="+harfHeight);
			//console.log(el.position)

			let newObj3D=new THREE.Object3D();

			$.each(targetMeshes,(id0,el0)=>{

				let geometry=el0.geometry;
				let pAttr=geometry.attributes.position;
				let uvAttr=geometry.attributes.uv;
				let positions=new Float32Array(pAttr.count*3);
				let uvs=new Float32Array(uvAttr.count*2);
				let pArray=pAttr.array;
			
				for(var n=0;n<pAttr.count;n++){
					var pV3=new THREE.Vector3(pArray[n*3]-center.x,pArray[n*3+1]-center.y,pArray[n*3+2]-center.z);
					pV3.applyQuaternion(revQ);
					positions[n*3]=pV3.x;
					positions[n*3+1]=pV3.y;
					positions[n*3+2]=pV3.z;

					uvs[n*2]=uvAttr.array[n*2];
					uvs[n*2+1]=uvAttr.array[n*2+1];
				}
				//geometry.needsUpdate=true;
				let newGeometry=new THREE.BufferGeometry();
				newGeometry.addAttribute('position',new THREE.BufferAttribute(positions,3));
				//newGeometry.computeBoundingBox();
				//newBoundingBox.union(newGeometry.boundingBox);
				newGeometry.addAttribute('uv',new THREE.BufferAttribute(uvs,2));
				if(id==3){
					if(el0.material.name=="bil1_mado"){
						//el0.material=material2;
					}
					//console.log(el0.material)
				}else if(id==1 || id==2 ||id>=4){
					
					if(el0.material.name=="bil1_mado"){
						
						//el0.material=material1;
					}else(el0.material.name=="bil3"){
						//console.log(el0.material);
					}
					//if(el0.material.name=="bil1_mado"){
						//el0.material=material1;
					//}
				}
				let mesh=new THREE.Mesh(newGeometry,el0.material);
				//mesh.applyQuaternion(revQ);
				//mesh.position.y=-harfHeight*0.3;
				
				newObj3D.add(mesh);
				//mesh.position.copy(bacePosition);
				mesh.position.y-=0.5*harfHeight*0.3;
				mesh.position.x-=0.5*boxSize.x*0.3;
				mesh.position.z-=0.5*boxSize.z*0.3;
				
				//newObj3D.quaternion.copy(q);
				//newObj3D.position.copy(bacePosition);
				
				
				//BuilObj3D.add(newObj3D);
			})

			newObj3D.position.copy(bacePosition);
			
			newObj3D.quaternion.copy(q);
			newObj3D.visible=false;


			//if(id>=11 && id<19){
			//if(id==16){
				//あとで追加したビル
				BuilObj3D.add(newObj3D);
				//console.log(newObj3D);
			//}
			
			
			
		
		})

		//console.log("BuilObj3D=");

		//console.log(BuilObj3D);



		//let boundingBox=new THREE.Box3();
		//let builParts=[];
		//let BuilCount=0;

		
		let updateCount=0;
		let updateCountMax=50;
		let updateDelay=100;
		let RandomNums=[];
		for(var n=0;n<30;n++){
			RandomNums.push(n);
		}
		RandomNums=utils.makeRandomArray2(RandomNums);
		updateDelays=[];
		for(var n=0;n<30;n++){
				updateDelays[n]=updateDelay*0.5+updateDelay*0.5*0.1*RandomNums[n];
				//console.log("key="+(RandomNums.slice(n,n+1)))
		}
		//console.log(updateDelays)
	

		this.update=()=>{
			updateCount+=1;
			//let delayCount=(updateCount<updateDelay)?0:updateCount-updateDelay;
			
			//console.log("ratio="+ratio)
			//if(delayCount<=updateCountMax+updateDelay){
				//let ratio=(delayCount<=updateCountMax)?delayCount/updateCountMax:1;
				//ratio=(ratio==0)?0.01:ratio;
				$.each(BuilObj3D.children,(id,el)=>{
					let delay=updateDelays[id];
					let delayCount=(updateCount<delay)?0:updateCount-delay;
					if(el.visible==false && delayCount>0){
						el.visible=true;
					}
					let delayCountMax=updateDelays[BuilObj3D.children.length-id-1]*2;

					delayCountMax=updateCountMax;


					let ratio=(delayCount<=delayCountMax)?delayCount/delayCountMax:1;
					//let ratio=(delayCount<=delayCountMax)?delayCount/delayCountMax:1;
					ratio=Math.pow(ratio,0.25);
					//console.log(id+":"+ratio)
					if(ratio>0){
						el.scale.set(ratio,Math.pow(ratio,1.5),ratio);
					}else{
						el.scale.set(0.01,0.01,0.01);

					}

				})
			//}

		}


		
		


	}

	function analizeLoadFbx(_obj){

		let obj3D=new THREE.Object3D();

		//console.log("analizeLoadFbx")
		//console.log(_obj)

		let name=_obj.name;
			
		let attrPosition=_obj.geometry.attributes.position;
		let attrNormal=_obj.geometry.attributes.normal;
		if(_obj.geometry.attributes.uv2){
			let attrUv=_obj.geometry.attributes.uv2;
		}else{
			let attrUv=_obj.geometry.attributes.uv;
		}
		let group=_obj.geometry.groups;


		let positionArray=attrPosition.array;
		let positionCount=attrPosition.count;
		let normalArray=attrNormal.array;
		if(attrUv){
			let uvArray=attrUv.array;
		}


		let materials=_obj.material;//配列

		for(var l=0;l<group.length;l++){
				let start=group[l].start;
				let count=group[l].count;
				let materialIndex=group[l].materialIndex;


				let positions=new Float32Array(count*3);
				let normals=new Float32Array(count*3);
				if(uvArray){
					let uvs=new Float32Array(count*2);
				}

				for(var n=start;n<count+start;n++){
					let countZero=(n-start)*3;
					let countNow=n*3;
					let uvZero=(n-start)*2;
					let uvNow=n*2;
					
					positions[countZero]=positionArray[countNow];
					positions[countZero+1]=positionArray[countNow+1];
					positions[countZero+2]=positionArray[countNow+2];
					normals[countZero]=normalArray[countNow];
					normals[countZero+1]=normalArray[countNow+1];
					normals[countZero+2]=normalArray[countNow+2];
					if(uvArray){
						uvs[uvZero]=uvArray[uvNow];
						uvs[uvZero+1]=uvArray[uvNow+1];
					}
				}
				let newGeometry=new THREE.BufferGeometry();
				newGeometry.addAttribute('position',new THREE.BufferAttribute(positions,3));
				newGeometry.addAttribute('normal',new THREE.BufferAttribute(normals,3));
				if(uvs){
					newGeometry.addAttribute('uv',new THREE.BufferAttribute(uvs,2));
				}
				let material=((materials.length)?materials[materialIndex]:materials;

				material.flatShading=true;
				material.skinning=false;
				material.side=THREE.DoubleSide;

				let mesh=new THREE.Mesh(newGeometry,material);
				

				obj3D.add(mesh);

				
			}


		return obj3D;
	}

	



}